/*make sure to import all the other css files here for them to work in the project*/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: "DM-Sans-Bold";
  src: url("../fonts/Poppins/DMSans-Bold.ttf");
}
@font-face {
  font-family: "DM-Sans-Medium";
  src: url("../fonts/Poppins/DMSans-Medium.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/Inter/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/Inter/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../fonts/Inter/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("../fonts/Inter/Inter-Thin.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../fonts/Inter/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Black";
  src: url("../fonts/Inter/Inter-Black.ttf");
}

.min-width-10px {
  min-width: 10px;
}

.max-width-400px {
  max-width: 400px;
}

.position-right-5vw {
  right: 5vw;
}

.max-width-243 {
  max-width: 243px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.remove-prefill-autofill input:-webkit-autofill,
.remove-prefill-autofill input:-webkit-autofill:hover,
.remove-prefill-autofill input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}

.gradient-background-base {
  background: linear-gradient(
    221.75deg,
    rgba(52, 231, 242, 0.1) -5.6%,
    rgba(169, 203, 255, 0.12) 24.42%,
    rgba(238, 250, 249, 0) 61.42%
  );
}
.gradient-background-chatbot {
  background: linear-gradient(
    214.34deg,
    rgba(218, 211, 255, 0.33) -3.06%,
    rgba(255, 222, 238, 0.4) 18.34%,
    rgba(255, 255, 255, 0) 51.41%
  );
}
.gradient-background-deploy {
  background: linear-gradient(
    217.21deg,
    rgba(255, 239, 98, 0.23) 0.6%,
    rgba(249, 147, 112, 0.08) 19.99%,
    rgba(238, 250, 249, 0) 46.88%
  );
}
.pricing-background {
  background: var(--23, linear-gradient(141deg, #5e66d6 0%, #ace2f4 100%));
}
input[type="checkbox"]:checked {
  background: #5e66d6;
  color: white;
  border-radius: 2px;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white;
  height: 16px;
  width: 16px;
  border: 1px solid #d9d9d9;
  color: white;
  border-radius: 3px;
}

input[type="checkbox"]:after {
  content: " ";
  position: relative;
  left: 33%;
  top: 14%;
  width: 26%;
  height: 66%;
  outline: none;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
  border-radius: 3px;
}

input[type="checkbox"]:checked:after {
  display: block;
}

.alert-toast {
  height: auto;
  padding: 23px 22px 22px 18px;
  max-width: 500px;
  max-height: 200px;
  overflow-wrap: break-word;
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background-color: #edf2f7;
}
.private-scroll-x::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Scrollbar thumb */
.private-scroll-x::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

.private-scroll-x::-moz-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

/* Scrollbar track */
.private-scroll-x::-webkit-scrollbar-track {
  background-color: #edf2f7;
}

.private-scroll-x::-moz-scrollbar-track {
  background-color: #edf2f7;
}

.border-thin {
  border: 5px dashed #5e66d6;
  position: relative;
  border-radius: 12px;
}

.border-thin:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  border: 5px solid white;
  pointer-events: none;
  border-radius: 8px;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ced8e2;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(94, 102, 214, 1);
}

input:checked + .slider:before {
  transform: translateX(11px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .css-1ybzgs6-MuiTableCell-root.TableSelectCell-cell {
    text-align: left !important;
  }
  .css-y0x3xi.TableSelectCell-cell {
    padding: 0px !important;
    text-align: left !important;
  }
  .css-zun73v {
    padding: 0px !important;
  }
}

.css-1ybzgs6-MuiTableCell-root .TableSelectCell-checkbox {
  padding: 0px !important;
}
.css-1ybzgs6-MuiTableCell-root {
  width: 10px !important;
  padding: 0px !important;
}

.webkit-smoothing {
  -webkit-font-smoothing: antialiased;
}

.markdown {
  white-space: pre-line;
}

.markdown, .markdown-bot table {
  border-collapse: collapse;
  width: 100%;
}

.markdown table th,
.markdown table td {
  border: 1px solid black;
  padding: 8px;
}

.markdown-bot table th,
.markdown-bot table td {
  border: 1px solid black;
  padding: 8px;
}

.markdown, .markdown-bot table th {
  text-align: left;
}

.markdown a {
  color: #5e66d6;
}

.markdown ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.markdown ol {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.markdown li {
  display: flex;
  gap: 4px;
}

.markdown li:before {
  content: "•";
  padding-right: 5px;
}

.markdown-bot ul {
  list-style: circle;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.markdown-bot ol {
  list-style: decimal;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.markdown-bot p {
  white-space: pre-wrap;
  margin: 5px 0;
}

.truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fit-width {
  width: fit-content !important;
}

#basic {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #ced8e2;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}
#basic:checked {
  background-color: #5e66d6;
}
#advance {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #ced8e2;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}
#advance:checked {
  background-color: #5e66d6;
}

.select-active {
  background-image: url("/images/dropup-arrow.svg");
  cursor: pointer;
}

.select-inactive {
  background-image: url("/images/dropdown-arrow.svg");
  cursor: pointer;
}

.styled-select-category {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-style: normal;
  color: #949cae;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 95% center;
}

.css-173nqtf .ResizingControl-resizeHandleLine {
  background-color: #dddfde !important;
  opacity: 1 !important;
}
.css-pye9lr .CellLayout-resizeHandleLine {
  background-color: #dddfde !important;
  opacity: 1 !important;
}
@media (pointer: fine) {
  .css-1o6p3b3-MuiTableCell-root .CellLayout-resizeHandleLine {
    opacity: 1 !important;
  }
}

@media (pointer: fine) {
  .css-pye9lr .CellLayout-resizeHandleLine {
    opacity: 1 !important;
  }
}

.z-299 {
  z-index: 299;
}

.css-173nqtf.ResizingControl-resizeHandleSecondLine {
  opacity: 0 !important;
}

.z-999 {
  z-index: 999;
}



/**
 * Markdown Editor Styles
**/

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: auto;
  border-radius: 2px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-radius: 15px;
}

.editor-input {
  min-height: 64px;
  max-height: 300px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  font-family: "Inter" Helvetica Arial Lucida;
  outline: 0;
  padding: 20px 18px;
  caret-color: #444;
  overflow: auto;
}

.editor-placeholder {
  color: #d9d9d9;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 20px;
  left: 20px;
  font-size: 18px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
  font-family: "Inter";
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  list-style-type: decimal;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  list-style-type: disc;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

.react-datepicker__close-icon::after{
 content: 'X' !important; 
  /* background-color: '#fff' !important; */
  /* background-size: contain !important;
  background-position: center !important; */
  padding: 1px;
}